:root {
    --LightGrey: rgb(119, 128, 130);
    --DarkGrey: rgb(52, 57, 58);
}
#ga-logo {
    // border: 2px solid red;
    // position: absolute;
    position: relative;
    // left: 340px;
    right: 60px;
    top: 465px; 
    width: 50px;
    z-index: -1;
}



.contact-icon-container {
    // border: 2px solid aqua;
    // width: 400px;
    // margin: 0 auto;
    // margin-bottom: 100px;  
    // margin-top: 100px;
}




.resume-main-section {
    // border: 2px solid red;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 60px;

    .resume-header {
        // border: 2px solid red;
        margin: 0 0 20px 0;
        // object-fit: contain;
    }

    #brand-statment {
        // border: 2px solid red; 
        font-size: 1.35em;
        font-weight: 500;
        text-align: right;
        margin-bottom: 50px;
    }

    h2 {
        // color: red;
        color: var(--LightGrey);
        margin: 10px 0 10px 0;
        text-align: left;
        font-size: 1.7em;
    }
    h3 {
        color: black;
        text-align: left;
        margin-top: 30px;
        font-size: 1.3em;
        text-decoration: none;
    }
    h3 span {
        // color: red;
        
        font-size: .8em;
        margin-left: 20px;
    }
    #overall {
        // color: red; 
        text-align: left;
        margin-top: 10px;
    }

    .resume-copy {
        // color: red;
        color: var(--DarkGrey);
        text-align: left;
        margin-top: 10px;
        line-height: 25px;
        font-size: 19px;

        & span {
            color: red;
            color: var(--DarkGrey);
            font-size: 1.1em;
            font-weight: 600;
        }
    }

    .project-container {

        .project-headings {
            // color: red;
            color: var(--DarkGrey);
            text-align: left;
            margin: 20px 0 -15px 0;
            font-size: 1.3em;
        }
        .project-copy {
            // color: red;
            margin: 15px 0 0 0;
            text-align: left;
            font-size: 19px;
            line-height: 30px;
        }
        
    }


    ///////////////////////////////////////////////////////////////////////
    // JOBs here ///////////
    ///////////////////////////////////////////////////////////////////////
    .job-container {
        // border: 2px solid aqua;
        margin: 40px 0 0 0; 
        .job {
            // color: red;
            text-align: left;
            margin-top: 20px;
            font-size: 1.3em;
        }
    
        .job-title {
            color: rgb(139, 162, 158); 
            text-align: left;
            font-size: 1.4em;
        }
        .project-copy {
            // color: red;
            margin: 15px 0 0 0;
            text-align: left;
            font-size: 19px;
            line-height: 30px;
        }

    }
    
    // & img {
    //     // border: 2px solid blue;
    //     max-width: 1200px;
    //     margin: 40px 0 40px 0; 
    // }


}