.designer-main-section {
    // border: 2px solid red;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 83.2vh;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}