* {
    box-sizing: border-box;
}
:root {
    --fontBlack: #6E6E6E;
    --buttonColor: #91B0B8;
    --buttonColorHover: #66878f;
}
body {
    // background-color: red;
    height: 100%;
    margin: 0;
}

 .main-content {
    //  border: 2px solid red;
     max-width: 1200px;
     margin: 0 auto;
     min-height: 75vh;
     margin-top: 180px;

     #main-image {
        // border: 2px solid aquamarine;
        margin: 70px 0 -80px 0;
        background-image: url('https://res.cloudinary.com/dhad6e9gj/image/upload/v1627942094/Portfolio%20Website%202/hannah-wei-aso6SYJZGps-unsplash_sn0lpw.jpg');
        background-repeat: no-repeat;
        background-size: 100%;
        padding-bottom: 28em;
        display: flex;
        justify-content: flex-end;
        // z-index: -1;
    }
    #icons {
        // border: 2px solid red;
        width: 80px;
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 0;

        & div {
            // border: 2px solid red;
            z-index: -1;
            // opacity: (.2);
            margin: 5px 0 5px 0;
        }
    }
    #main-branding-statement {
        // border: 2px solid red;
        margin: 0 auto;
        font-size: 1.3em;
        width: 80%;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 50px;
        color: rgb(92, 92, 92);
         
        & h3 {
            // color: red;
        }

        & span {
            // color: red; 
            // color: var(--buttonColorHover);
            color: black;
            font-weight: 600;
        }
    }
    #main-my-work h3 {
        // border: 2px solid red;
        font-size: 1.5em;
        margin-top: 20px;
        margin: 40px 0 50px 0;
    }
 }


.sticky-footer {
    position: sticky;
    top: 100%;
} 

.sticky-footer::after {
    content: '';
    display: block;
}

