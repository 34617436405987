:root {
    --black: rgb(55, 55, 55);
}

.header {
    // border: 2px solid red;
    position: sticky;
    top: 0;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    z-index: 1;

    .header-container {
        // border: 4px solid blue;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        // border: 2px solid red; 
        // margin: 0 0 0 250px;
    }

    .logo :hover {
        // border: 3px solid blue;
        transition: all 0.4s ease 0s;
        transform: scale(1.1)
    }
    .software-developer {
        // border: 2px solid red;
        margin: 0 0 8px 25px;
    }
   
    ul {
        // border: 2px solid red;
        display: flex;
        list-style-type: none;
        // padding-right: 250px;
    }
    
    li a {
        // background-color: red;
        padding: 0 20px 0 20px;
        color: white; 
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        transition: all 0.3s ease 0s;
    }
    & :hover {
        color: #0088a9;
    }


// bottom of header
}


