@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

 * {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     font-family: "Montserrat"; 
 }
  

