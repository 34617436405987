.contact-main-section {
    // border: 2px solid red;
    // margin: 150px 0 0 0;

    // max-width: 1200px;
    // margin: 0 auto;
    // min-height: 75vh;
    // margin-top: 100px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    // border: 2px solid red;
    // margin: 150px 0 0 0;
    max-width: 1200px;
    margin: 0 auto;
    // min-height: 83.2vh;
    margin-top: 140px;

    .contact-section1 {
        // border: 2px solid red;
        
        #notepad {
            // border: 2px solid red;
            margin-top: 230px;
            position: relative;
            top: -450px
        }
       
        .contact-form-container {
            // border: 3px solid hotpink;
            position: relative;
            top: -1350px;
            left: 100px;
            height: 750px;
            background-color: #91B0B8;
            background-color: #eaf2f2;
            border-radius: 4px;
            
            & #name-email-fields {
                // border: 2px solid aqua;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                width: 80%;
                & #name {
                    // border: 2px solid red;
                    background-color: white;
                    font-size: 1.3em;
                    margin: 60px 0 0 0; 
                    height: 60px;
                    width: 40%;
                    border-radius: 3px;
                    border: none;
                    text-align: center;
                }
                & #email {
                    // border: 2px solid red;
                    background-color: white;
                    font-size: 1.3em;
                    margin: 60px 0 0 0; 
                    height: 60px;
                    width: 40%;
                    border-radius: 3px;
                    border: none;
                    text-align: center;
                }
            }
            
            
            & #message-field {
                // border: 2px solid red;
                width: 80%;
                height: 300px;
                margin: 0 auto;
                position: relative;
                top: 100px;
    
                & #message {
                    width: 100%;
                    height: 100%;
                    font-size: 1.3em;
                    border: none;
                    border-radius: 3px;
                }
                & ::placeholder {
                    // border:2px solid green;
                    text-align: center;
                }
                & #contact-submit-button {
                    // border: 2px solid red;
                    width: 100%;
                    height: 90px;
                    border: none;
                    border-radius: 3px;
                    margin: 10px 0 0 0;
                    background-color: #338EF9;
                    color: white;
                    font-size: 1.4em;
                    font-weight: 600;  
                    transition: all 0.4s ease 0s;
                    z-index: 1;
                }
                & input[type='submit']#contact-submit-button:hover {
                      cursor: pointer;
                      transform: scale(1.1);
                  }               
            }
        }
        
        #arrow {
            // border: 5px solid hotpink;
            position: relative;
            top: 600px;
            left: 490px;
            z-index: 1;
        }




    }
    .contact-left {
        // border: 2px solid purple;
        width: 500px;
        text-align: left;
        & h1 {
            font-size: 5em;
        }
        & p {
            font-size: 1.4em;
            font-weight: 600;
            margin: 10px 0 20px 0;
        }
        & article {
            line-height: 25px;
        }

        .contact-links {
            // border: 2px solid aqua;
            display: flex;
            width: 100px;
            justify-content: space-between;
            margin-top: 50px;

            #linkedin {
                // border: 2px solid red;
                display: flex;
                align-items: center;  
                width: 120px;
                & img {
                    margin-right: 10px;
                    transform: scale(.8);
                }
            }
            #github {
                // border: 2px solid red;
                display: flex;
                align-items: center;  
                width: 120px;
                & img {
                    margin-right: 10px;
                    transform: scale(.8);
                }
            }
        }
    }
    







}