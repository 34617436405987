* {
    box-sizing: border-box;
}
:root {
    --fontBlack: #6E6E6E;
    --buttonColor: #91B0B8;
    --buttonColorHover: #66878f;
}
.project-card-wrapper {
    border: 2px solid aquamarine;
    display: flex;
    flex-wrap: wrap;

}
.project-card-container {
    border: 2px solid red;
    // background-color: var(--fontBlack);
    // background: linear-gradient(to top, var(--fontBlack), white);
    border: 1px solid var(--fontBlack);
    height: 750px;
    display: flex;
    flex-direction: column;
    margin: 10px 20px 40px 20px;
    border-radius: 5px;
    line-height: 25px;
    

    .project-card {
        // border: 2px solid aquamarine;
        z-index: -1;
        width: 350px;
        height: 500px;
        padding: 0 20px 0 20px;
        color: rgb(104, 104, 104);
        margin: 0 auto;

        & h3 {
            // color: red;
            color: rgb(76, 76, 76);
            margin: 0 0 10px 0;
            padding-top: 20px;
            border-top: 1px solid rgb(202, 202, 202);
        }
    }

    .project-card-icon {
        // border: 2px solid red;
        margin: 20px 0 20px 0;
        // filter: drop-shadow(5px 4px 4px rgb(116, 115, 115)); // this makes the icon 'stick' above the footer? Not sure why...? 
        // opacity: (.8);
        // z-index: -1;
        
        & :hover {
            transition: all .7s ease 0s;
            transform: scale(1.2) rotate(360deg);
            // border: 2px solid red;   
        }   
    }
////////////////////////////////////////////////////////////////////////////


    .project-card-button-container {
        // border: 2px solid red;
        width: 200px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        // margin-top: 40px;
        margin-bottom: 20px;

        .project-card-buttons {
            // background-color: aquamarine;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 30px;
            border-radius: 4px;
            background-color: var(--buttonColor);
            background-color: var(--buttonColorHover);
            margin-bottom: 20px;
        }
        .project-card-buttons a {
            // border: 2px solid hotpink;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 30px;
            border-radius: 4px;
            color: white;
            transition: all 0.2s 0s;
        }
        & :hover {
            cursor: pointer;
            background-color: var(--buttonColorHover); 
            background-color: var(--buttonColor); 
            font-size: 1.08em;
        }
    }   
     .tech  {
            // border: 2px solid red;
            border-top: 1px solid black;
            width: 60%;
            margin: 10px auto;
            display: flex;
            justify-content: space-between;
            // text-align: left;
        }
}

.project-card-main-collection {
    // border: 2px solid red;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}